.App {
  text-align: center;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@font-face {
  font-display: block;
  font-family: "CenturyGothic";
  src: url("../public/assets/fonts/century-gothic.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "BankGothicLight";
  src: url("../public/assets/fonts/bank-gothic-light-bt.woff2") format("woff2");
}

@font-face {
  font-display: block;
  font-family: "TimesNewRoman";
  src: url("../public/assets/fonts/times-new-roman.woff2") format("woff2");
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.appWrapper {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.container {
  padding: 0 12px;
}

.container h1, .container h2, .work h2 {
  font-weight: lighter;
}

.hamburgerMenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  border-bottom: 1px solid #e2e2e2;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  margin-bottom: 30px;
}

.hamburgerMenuItem {
  text-transform: uppercase;
}

.hamburgerMenuItem a {
  color: #333;
  text-decoration: none;
  font-weight: lighter;
}

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eee;
  padding: 16px 0;
  text-transform: uppercase;
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
  margin-bottom: 30px;
}

.menu {
  font-weight: lighter;
  display: flex;
  gap: 30px;
}

.navbar a {
  color: #333;
  text-decoration: none;
}

.image-container {
  position: relative;
  height: 100vh;
}


.fullscreen-image-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}


.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /*background: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0.3));*/
  z-index: 1;
}

.name {
  font-size: 28px;
  color: white;
  text-decoration: none;
  font-family: "CenturyGothic", sans-serif;
  text-transform: uppercase;
  font-weight: lighter;
}

.fullscreen-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;
}

.flex-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.centered-text {
  color: white;
  font-weight: bold;
  padding: 6px 12px;
  width: auto;
  cursor: pointer;
  border-radius: 1px;
}

.centered-text a {
  text-decoration: none;
}

.footer {
  display: flex;
  justify-content: space-between;
  padding: 18px;
  background-color: #2c323a;
  color: white;
}

.homeFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  background-color: white;
  color: #2c323a;
}

.socialIcons {
  display: flex;
  gap: 8px;
}

.work {
  display: flex;
  flex-direction: column;
  margin-bottom: 2em;
  gap: 1em;
}

.work h2 {
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3px;
}

.grid-item {
  width: 100%;
  position: relative;
  padding-bottom: 100%; /* 1:1 Aspect Ratio */
  overflow: hidden;
  cursor: pointer;
}

.grid-item:hover .overlay {
  opacity: 1;
}

.overlay {
  background-color: rgba(0, 0, 0, 0.7); /* nero semi-trasparente */
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
}

.overlay-text {
  color: white;
  font-size: 24px;
  font-style: italic;
}

.grid-item img {
  object-fit: cover;
  position: absolute;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 900px) {
  .name {
    color: #333;
  }
}


@media only screen and (min-width: 1024px) {
  .work {
    width: 30%;
    margin: 0 auto;
  }

  .name {
    font-size: 42px;
  }

  .centered-text {
    margin-left: 12%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
